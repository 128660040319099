import jquery from "jquery";

export function parallax() {
	jQuery(".move-down").parallaxContent({ shift: 22 });
	jQuery(".move-up").parallaxContent({ shift: 22, direction: "reverse" });
}

export function successStories() {
	(function ($) {
		setTimeout(() => {
			const carousel = $(".owl-carousel");
			const carouselItems = carousel.find(".dt-owl-item");

			carouselItems.each((_idx, elem) => {
				const button = $(elem).find(".dt-btn");
				const itemWrap = $(elem).find(".carousel-item-wrap");

				button.appendTo(itemWrap);
			});
		}, 100);
	})(jQuery);
}

export function numberList() {
	(function ($) {
		const numberList = $(".number-list");
		const list = numberList.find("li");

		if (list.length > 0) {
			list.each((_idx, elem) => {
				$(elem).wrapInner("<div></div>");
			});
		}
	})(jQuery);
}

export function form() {
	(function ($) {
		const form = $(".wpcf7-form");
		const buttonWrap = form.find(".button-wrap");
		const button = form.find(".wpcf7-submit");

		button.on("mouseenter", () => buttonWrap.toggleClass("active"));

		button.on("mouseleave", () => buttonWrap.toggleClass("active"));
	})(jquery);
}
