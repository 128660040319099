import "./scss/styles.scss";

import "parallax_content";
import "gsap/TweenMax";
import "gsap/TweenLite";
import "gsap/CSSPlugin";

import { successStories, numberList, parallax, form } from "./scripts/app";

window.addEventListener("DOMContentLoaded", parallax);
window.addEventListener("DOMContentLoaded", successStories);
window.addEventListener("DOMContentLoaded", numberList);
window.addEventListener("DOMContentLoaded", form);

// See https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
	import.meta.hot.accept("./app.js", (new_app) => {
		new_app?.app();
	});
}
